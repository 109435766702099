.noticia {
  .subtitle {
    ul {
      padding-left: 17px;
    }
  }
  .cuerpo {
    blockquote {
      position: relative;
      font-family: 'Playfair Display', serif;
      font-size: 24px;
      text-align: center;
      font-style: italic;
      font-weight: 400;
      margin: 4em auto 2em;
      max-width: 350px;

      ::before {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        content: "”";
        color: rgb(91, 198, 248);
        font-size: 140px;
        font-style: normal;
        position: absolute;
        top: -110px;
        left: 40%;
      }

      p:last-child{
        font-style: normal;
      }
    }
    .sumario {
      border-left: none;
      border-top: 2px solid #5bc6f8;
      float: left;
      font-family: playfair display,serif;
      font-size: 24px;
      font-style: normal;
      margin: 1em 2em 1em 0;
      padding: 1em 0 0;
      width: 40%; 
  } 
  .sumario_dere {
      border-left: none;
      border-top: 2px solid #5bc6f8;
      float: right;
      font-family: playfair display,serif;
      font-size: 24px;
      font-style: normal;
      margin: 1em 2em 1em 0;
      padding: 1em 0 0;
      width: 40%; 
  }
    .indice {
      background: #f5f4f4;
      padding: 15px;
      margin-bottom: 15px;
      h2 {
          font-size: inherit;
          position: relative;
          margin: 0 0 1rem;
          padding: 1rem 1rem 0 4rem;  
          &::before{
              background-image: url(/build/images/rw-indice.jpg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 2rem;
              height: 2rem;
              content: "";
              flex-shrink: 0;
              position: absolute;
              top: 0.8rem;
              left: .8rem;
              border-radius: 20px;
          }
      } 
      ul {
          list-style: decimal;
          padding-left: 2em;           
          margin-bottom: 0;  
          li {                  
              padding: 0px 5px 10px;
              color: #202020;
              font-weight: bold;
              a { 
                  color: #000;
                  font-weight: 400;
                  text-decoration: none;
                  background-color: transparent;
                  border-bottom: none;
                  &:hover{
                      color: inherit;
                      border-bottom: 1px solid rgba(0,0,0,.15);
                      text-decoration: none;
                  }
              } 
          }                              
      }
    }
    .raw-html-embed {
      .twitter-tweet{
        margin: 0 auto 16px !important;
        width: 53% !important;
      }
    }
    .reasonWhy{
      white-space: nowrap; 
    }
  }
}

@media (max-width: 991px) {
  .noticia {
    .cuerpo {
      .sumario {
        margin: 1em 1em 1em 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .noticia {
    .cuerpo {
      .sumario {
        font-size: 20px;
        min-width: 150px;
      }
    }
  }
}

@media (max-width: 575px) {
  .noticia {
    .title {
      font-size: 28px;
      margin: 30px 0 !important;
    }
  }
}

@media (max-width: 400px) {
  .noticia {
    .cuerpo {
      .sumario {
        width: 100%;
        float: unset;
        padding-top: 10px;
        margin: 2em 1em 2em 0;
      }
      .image_resized {
        width: 100% !important;
      }
    }
  }
}