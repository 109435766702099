.card.card-custom {
    > .card-header.card-header-tabs-line {
        .nav {
            button.nav-link {
                background: transparent;
                border: none;
                padding: 0.75rem 1.5rem !important;
                text-align: inherit;
            }
        }
    }
}

@media (max-width: 767px) {
    .card-header {
        padding: 2rem 2rem;
    }
    .card.card-custom.card-sticky {
        > .card-header {
            min-height: 44px;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            right: unset !important;
            box-shadow: none !important;
        }
    }
    .card.card-custom {
        > .card-header.card-header-tabs-line {
            .nav {
                .nav-link {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                } 
            }
        }
    }
    .nav.nav-tabs.nav-tabs-line {
        .nav-link {
            padding: 0.5rem 0;
            margin: 0 0.5rem;
        }
    }
    .toolbar-actions {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: #fff;
        width: 100%;
        justify-content: center;
        padding: 0.5rem 0;
        box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.1);
    }
}