#noticia_iconos, #publicidad_posicion, #publicidad_tipo{
    .checkbox-inline {
        justify-content: left;
    }
}
.form-check-inline {
    .form-check-input {
        position: absolute;
    }
}
  
.col-form-label {
    padding-top: 0;
    padding-bottom: 0.5rem;
}

.select2-container {
    display: block;
}

.vich-image img {
    max-width: 100%;
}

.audio-input {
    max-width: 100%;
    text-align: right;
    margin-bottom: 5px;
    margin-right: 15px;

    &.audio-input-empty [data-action="remove-audio"] {
        display: none;
    }
}

.image-input {
    max-width: 100%;
    display: block;

    .image-input-wrapper {
        height: 370px;
        width: 655px;
    }

    .add-imagen-principal {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        span {
            height: 100%;
        }
    }

    [data-action="complete"] {
        bottom: -5px;
        position: absolute;
        right: -10px;
        top: -15px;
    }

    [data-action="remove"] {
        top: 30px;
    }

    [data-action="remove-basic"] {
        top: 30px;
    }

    [data-action="refresh"] {
        top: 75px;
    }

    &.image-input-empty [data-action="complete"] {
        display: none;
    }

    &.image-input-outline .image-input-wrapper {
        background-position: 50%;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        max-width: 100%;
    }
}

.image-input-seo {

    .image-input-wrapper {
        height: 200px;
        width: 360px;
    }

    [data-action="remove"] {
        top: -15px;
    }

    [data-action="remove-basic"] {
        top: -15px;
    }

    [data-action="refresh"] {
        top: -15px;
    }
}

.seleccionar-media {
    cursor: pointer;
}

#form-pagina {
    .form-control.border-0 + .ck.ck-editor__editable_inline {
        border: none!important;
    }
}

#form-noticia {
    .form-control.border-0 + .ck.ck-editor__editable_inline {
        border: none !important;
    }

    // .seleccionar-noticias-modal .form-control {
    //     border: 1px solid #E4E6EF !important;
    // }

    // #noticia_prefijo, 
    // #noticia_slug, 
    // #seleccionar_media_filter_nombre {
    //     border: 1px solid #E4E6EF !important;
    // }

    .ck-content {
        min-height: 60px;
    }

    .form-control.border-0 {
        background: none;
    }
}

#noticia_titular {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    font-weight: 700 !important;
    line-height: 1.2;
    overflow: hidden;
    resize: none;
    text-align: center;
}

// Separator
hr.separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.checkbox-inline {
    justify-content: center;
}

// Plugin Seleccionar Noticias Modal y Media, LOADING...
.seleccionar-noticias-modal,
.seleccionar-medias-modal {
    .loading {
        position: relative;
        &::after {
            position: absolute;
            color: black;
            z-index: 1053;
            bottom: 50%;
            right: 50%;
            content: ' ';
            display: inline-block;
            width: 4rem;
            height: 4rem;
            vertical-align: text-bottom;
            border: .25em solid currentColor;
            border-right-color: transparent;
            border-radius: 50%;
            -webkit-animation: spinner-border .75s linear infinite;
            animation: spinner-border .75s linear infinite;
        }
        &::before {
            position: absolute;
            z-index: 1052;
            content: ' ';
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.3);
        }
    }
}

#htmlDegradadoEnlace {
    .form-group {
        margin-bottom: 0;
    }
    > .text-muted {
        font-size: 0.9rem;
    }
}

#textoDegradadoBoton {
    .contadorDegradado {
        text-align: right;
        font-weight: 600;
        .numero {
            color: #be0000;
        }
    }
}

.select2-results {
    background: #fff;
}

input[type='color'] {
    max-width: 60px;
}

@media (max-width: 575px) {
    .image-input {
        .image-input-wrapper {
            height: 230px;
        }
    }
}
