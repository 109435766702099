.max-1000 {
    margin: 0 auto;
    max-width: 1000px !important;
}

.hr-vertical {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.w-50px {
    width: 50px;
}

.text-font-brandpublisher {
    font-family: 'Pontano Sans', sans-serif;
    font-weight: bold;
}

.flex-1 {
    flex: 1;
}

.menu-icon.rotate {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    flex: 0 !important;
    margin-right: 17px;
    margin-left: -3px;
}

.new-style {
    padding-right: 15px!important;
    font-size: 2.2rem!important;
}

.new-style-scroll {
    font-size: 2.2rem!important;
}

.scroll-max{
    width: 56px!important;
    height: 56px!important;
    right: 100px !important;
}

.card-img-media-vert {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
}

.card-img-media-hori {
    width: 200px;
    height: 130px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
}
