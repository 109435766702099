.ck i {
    font-size: inherit;
    color: inherit;
}
#noticia_titular_editor{
    text-align: center;
    p{
        font-family: 'Playfair Display', serif;
        font-size: 3rem;
        font-weight: 700 !important;
        line-height: 1.2;
        overflow: hidden; 
        text-align: center;
    }
}
.ck-content {
    .text-small {
        font-size: 13px;
    }

    .text-big {
        font-size: 19px;
    }

    .text-huge {
        font-size: 25px;
    }
    h2, h3, h4 {
        font-size: inherit;
    }
}

.ck.ck-list__item .ck-button {
    &.text-small .ck-button__label {
        font-size: 13px;
    }

    &.text-big .ck-button__label {
        font-size: 19px;
    }

    &.text-huge .ck-button__label {
        font-size: 25px;
    }
}

.ck.ck-button.ck-heading_ladillo .ck-button__label {
    color: #59cdff;
    font-family: "Raleway", sans-serif !important;
    font-weight: bolder;
}
.ck.ck-button.ck-heading_ancla .ck-button__label {
    color: gray;
    font-family: "Raleway", sans-serif !important;
    font-weight: bolder;
}
 
.ck.ck-editor__editable_inline {
    border: 1px solid #E4E6EF;
    font-family: "Raleway", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;

    a {
        color: inherit;
        border-bottom: 1px solid rgba(102, 204, 255, 0.5);
        text-decoration: none;
    }

    u {
        border-bottom: 1px solid;
        text-decoration: none;
    }

    blockquote {
        position: relative;
        font-family: 'Playfair Display', serif;
        font-size: 24px;
        text-align: center;
        font-style: italic;
        font-weight: 400;
        margin: 4em auto 2em;
        max-width: 350px;

        ::before {
            font-family: 'Playfair Display', serif;
            font-weight: 400;
            content: "”";
            color: rgb(91, 198, 248);
            font-size: 140px;
            font-style: normal;
            position: absolute;
            top: -110px;
            left: 40%;
        }

        p:last-child{
            font-style: normal;
        }
        &.twitter-tweet {
            display: none;
         }
    }

    .sumario {
        border-left: none;
        border-top: 2px solid #5bc6f8;
        float: left;
        font-family: playfair display,serif;
        font-size: 24px;
        font-style: normal;
        margin: 1em 2em 1em 0;
        padding: 1em 0 0;
        width: 40%; 
    } 
    .sumario_dere {
        border-left: none;
        border-top: 2px solid #5bc6f8;
        float: right;
        font-family: playfair display,serif;
        font-size: 24px;
        font-style: normal;
        margin: 1em 0 1em 2em;
        padding: 1em 0 0;
        width: 40%; 
    }
    .ladillo {
        color: #59cdff;
        font-weight: bolder;
    } 
    .new-anclas {
        background: #f5f4f4;
        padding: 15px;
        margin-bottom: 15px;
        h2,h3 {
            font-size: inherit;
            position: relative;
            margin: 0 0 1rem;
            padding: 1rem 1rem 0 4rem;  
            &::before{
                background-image: url(/build/images/rw-indice.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 2rem;
                height: 2rem;
                content: "";
                flex-shrink: 0;
                position: absolute;
                top: 0.8rem;
                left: .8rem;
                border-radius: 20px;
            }
        } 
        ul, ol{
            list-style: decimal;
            padding-left: 2em;           
            margin-bottom: 0;  
            li {                  
                padding: 0px 5px 10px;
                color: #202020;
                font-weight: bold;
                a { 
                    color: #000;
                    font-weight: 400;
                    text-decoration: none;
                    background-color: transparent;
                    border-bottom: none;
                    &:hover{
                        color: inherit;
                        border-bottom: 1px solid rgba(0,0,0,.15);
                        text-decoration: none;
                    }
                } 
            }                              
        }
    }

    .raw-html-embed {
        .infogram-embed {
            iframe {
                height: auto;
                display:none;
            }
            iframe:first-child{
                display:block;
            }
        }
        .twitter-tweet{
            margin: 0 auto 16px !important;
            width: 53% !important;
        }
    }
    .reasonWhy{
        white-space: nowrap;
        span{
            color: #fff;
        }
    }
}

.ck.ck-link-form_layout-vertical.ck-link-form {
    min-width: 36em;
}

.noticia {
    .cuerpo {
        .bloque-intertext.ck-widget {
            border: solid 1px hsl(0, 0%, 77%);
            border-radius: 2px;
            margin-bottom: 1rem;
            padding: 0 10px;
        
            h2 {
                border-top: 1px solid #e5e5e5 !important;
                line-height: 0;
                margin: 14px 0 20px;
                width: 100%;
        
                > span {
                    background: #fff;
                    padding-right: 1em;
                    font-size: 12px;
                    font-weight: 700 !important;        
                }
            }
        
            .noticia-relacionada {
                display: flex;
                padding: 10px;
                border: solid 1px hsl(0, 0%, 77%);
                border-radius: 2px;
                margin: 10px 0;
        
                h3 a {
                    font-family: "Playfair Display", serif !important;
                    font-size: 20px !important;
                    display: block;
                    margin: 0 0 8px 24px;
                    font-weight: 500;
                    color: #000;
                    line-height: 24px;
                }
        
                > p a {
                    font-family: "Playfair Display", serif !important;
                    font-size: 20px !important;
                    display: block;
                    margin: 0 0 8px 24px;
                    font-weight: 500;
                    color: #000;
                    line-height: 24px;
                }
        
                > p {
                    width: auto !important;
                    margin: 0 !important;
                }
        
                > .image {
                    margin: 0 24px 0 0;
                    img{
                        width: 120px;
                        height: 95px;
                    }
                }
            }
        
            // .title-noticias-relacionadas {
            //     position: relative;
            //     margin-bottom: 1.5rem;
        
            //     &::after {
            //         content: "";
            //         position: absolute;
            //         bottom: 0;
            //         left: 0;
            //         right: 0;
            //         height: 0.3em;
            //         border-top: 1px solid hsl(0, 0%, 77%);
            //         z-index: 0;
            //     }
        
            //     .title-span-noticias-relacionadas {
            //         background-color: white;
            //         padding-right: 10px;
            //         position: absolute;
            //         z-index: 1;
            //     }
            // }
        
            &::before {
                content: '';
                display: block;
                position: absolute;
                z-index: 1000;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }
       
        
    }
}

.galeria-container {
    figure {
        display: block;
        background: #000;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 50% 0 0 0;
        img {
            width: auto !important;
            display: block;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.galeria-container[data-ancho="16:9"] {
    figure {
        padding: 56.25% 0 0 0;
    }
}
// .galeria-container[data-ancho="635px"] {
//     max-width: 635px;
//     margin: 0 auto;
//     img{
//         max-width: 635px;
//     }
// }
// .galeria-container[data-ancho="500px"] {
//     max-width: 500px;
//     margin: 0 auto;
//     img{
//         max-width: 500px;
//     }
// }
// .galeria-container[data-ancho="550px"] {
//     max-width: 550px;
//     margin: 0 auto;
//     img{
//         max-width: 550px;
//     }
// }
// .galeria-container[data-ancho="450px"] {
//     max-width: 450px;
//     margin: 0 auto;
//     img{
//         max-width: 450px;
//     }
// }
// .galeria-container[data-ancho="400px"] {
//     max-width: 400px;
//     margin: 0 auto;
//     img{
//         max-width: 400px;
//     }
// }
// .galeria-container[data-ancho="350px"] {
//     max-width: 350px;
//     margin: 0 auto;
//     img{
//         max-width: 350px;
//     }
// }
.bloque-intertext.ck-widget {
    border: solid 1px hsl(0, 0%, 77%);
    border-radius: 2px;
    margin-bottom: 1rem;
    padding: 0 10px;

    h2 {
        border-top: 1px solid #e5e5e5 !important;
        line-height: 0;
        margin: 14px 0 20px;
        width: 100%;

        > span {
            background: #fff;
            padding-right: 1em;
            font-size: 12px;
            font-weight: 700 !important;        
        }
    }

    .noticia-relacionada {
        display: flex;
        padding: 10px;
        border: solid 1px hsl(0, 0%, 77%);
        border-radius: 2px;
        margin: 10px 0;

        h3 a {
            font-family: "Playfair Display", serif !important;
            font-size: 20px !important;
            display: block;
            margin: 0 0 8px 0;
            font-weight: 500;
            color: #000;
            line-height: 24px;
        }

        > p a {
            font-family: "Playfair Display", serif !important;
            font-size: 20px !important;
            display: block;
            margin: 0 0 8px 0;
            font-weight: 500;
            color: #000;
            line-height: 24px;
        }

        > p {
            width: auto !important;
            margin: 0 !important;
        }

        > .image {
            margin: 0 24px 0 0;
            flex: 0 0 120px;
            img{
                width: 120px;
                height: 95px;
            }
        }
    }

    // .title-noticias-relacionadas {
    //     position: relative;
    //     margin-bottom: 1.5rem;

    //     &::after {
    //         content: "";
    //         position: absolute;
    //         bottom: 0;
    //         left: 0;
    //         right: 0;
    //         height: 0.3em;
    //         border-top: 1px solid hsl(0, 0%, 77%);
    //         z-index: 0;
    //     }

    //     .title-span-noticias-relacionadas {
    //         background-color: white;
    //         padding-right: 10px;
    //         position: absolute;
    //         z-index: 1;
    //     }
    // }

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1000;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}
.slider.ck-widget {
    .galeria-container .slider-item{
        display: none;
        &:first-child{
            display: block;
            cursor: none !important;
            pointer-events: none !important;
        }
    }
}
.cuerpo.edit-inline {
    .bloque-intertext {
        border: none !important;
        border-bottom: 1px solid #e5e5e5 !important;
    
        h2 {
            > span {
                font-size: 12px !important;
            }
        }
    
        .noticia-relacionada {
            border: none !important;
            margin: 0 0 24px 0 !important;
            padding: 0 !important;
    
            h3 a {
                font-family: "Playfair Display", serif !important;
                font-size: 20px;
                width: 90% !important;
                display: block;
                margin: 0 0 8px 24px;
                font-weight: 500;
                color: #000;
                line-height: 24px;
            }

            p a {
                font-family: "Playfair Display", serif !important;
                font-size: 20px;
                width: 90% !important;
                display: block;
                margin: 0 0 8px 24px;
                font-weight: 500;
                color: #000;
                line-height: 24px;
            }
    
            > .image {
                margin: 0;
                flex: 0 0 120px;
                img{
                    width: 120px;
                    height: 95px;
                }
            }
        }
    }
}

.ck-widget.raw-html-embed .raw-html-embed__preview-content {
    width: 100%;
}

/* .soundcite-loaded[texto]::after {
    display: inline;
    content: attr(texto);
}  */ 

.soundcite-loaded {
    br {
        display: none;
    }
}
.raw-html-embed.ck-widget{
    &::before{
        content: 'General';
        display: block;
        position: absolute;
        z-index: 1000;
        left: 0; 
        top: 21px;
        font-size: 16px;
        color: #ffffff;
        background-color: #181C32;
        border-color: #181C32;
        font-weight: 600; 
        padding: 0 10px;
    }
}
.desktop.ck-widget {
    .raw-html-embed.ck-widget{
        &::before{
            content: 'Desktop';
            display: block;
            position: absolute;
            z-index: 1000;
            left: 0; 
            top: 21px;
            font-size: 16px;
            color: #ffffff;
            background-color: #181C32;
            border-color: #181C32;
            font-weight: 600; 
            padding: 0 10px;
        }
    }
   
}
.mobile.ck-widget {
    .raw-html-embed.ck-widget{
        &::before{
            content: 'Mobile';
            display: block;
            position: absolute;
            z-index: 1000;
            left: 0; 
            top: 21px;
            font-size: 16px;
            color: #ffffff;
            background-color: #181C32;
            border-color: #181C32;
            font-weight: 600; 
            padding: 0 10px;
        }
    } 
}

.ckeditor-h700 .ck-content {
    min-height: 700px !important;
}

@media (max-width: 991px) {
    .ck.ck-editor__editable_inline {
        .sumario {
            margin: 1em 1em 1em 0;
        }
        .sumario_dere {
            margin: 1em 0 1em 1em;
        }
    }
}

@media (max-width: 767px) {
    .ck.ck-editor__editable_inline {
        .sumario {
            font-size: 20px;
            min-width: 150px;
        }
        .sumario_dere {
            font-size: 20px;
            min-width: 150px;
        }
    }

    .cuerpo.edit-inline .bloque-intertext .noticia-relacionada > .image {
        flex: 0 0 80px;
    }
    .noticia .cuerpo .bloque-intertext.ck-widget .noticia-relacionada > p a {
        font-size: 16px !important;
        line-height: 19px;
    }
    .noticia .cuerpo .bloque-intertext.ck-widget .noticia-relacionada > .image {
        margin: 0 15px 0 0;
    }
    .bloque-intertext.ck-widget .noticia-relacionada > p a {
        font-size: 16px !important;
        line-height: 19px;
    }
    .bloque-intertext.ck-widget .noticia-relacionada > .image {
        margin: 0 15px 0 0;
    }
    .bloque-intertext.ck-widget .noticia-relacionada > .image img {
        width: 80px;
        height: auto;
    }
    .bloque-intertext.ck-widget .noticia-relacionada > .image {
        flex: 0 0 80px;
    }
}

@media (max-width: 400px) {
    .ck.ck-editor__editable_inline {
        .sumario {
            width: 100%;
            float: unset;
            padding-top: 10px;
            margin: 2em 1em 2em 0;
        }
        .sumario_dere {
            width: 100%;
            float: unset;
            padding-top: 10px;
            margin: 2em 1em 2em 0;
        }
    }
}
