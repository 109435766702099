.table-responsive{
    min-height: 50vh;
}
.datatable.datatable-default {
    &.datatable-loaded {
        > .datatable-table {
            display: table;

            > .datatable-head,
            > .datatable-body,
            > .datatable-foot {
                display: table-header-group;

                .datatable-row {
                    display: table-row;

                    > .datatable-cell {
                        display: table-cell;

                        > span > i {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}

.table.table-head-bg {
    th {
        background-color: #F3F6F9;
        border-bottom: 0;
        letter-spacing: 1px;

        &:first-child {
            border-top-left-radius: 0.85rem;
            border-bottom-left-radius: 0.85rem; 
        }

        &:last-child {
            border-top-right-radius: 0.85rem;
            border-bottom-right-radius: 0.85rem; 
        }
    }
}

.edit-table {
    display: inline-block;
    i {
        transition: all 0.15s ease;
    }
    &:hover {
        i {
            color: #000;
        }
    }
}

.title-tofront {
    transition: all 0.15s ease;
    color: #3F4254;
    &:hover {
        color: #000;
        cursor: pointer;
    }
}

.icono-tabla {
    padding: 10px 0;
    background: #f3f3f3;
}

.seleccionar-galeria-modal {
    .datatable-cell {
        img {
            max-width: 120px;
        }
    }
}
