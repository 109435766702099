@font-face {
    font-family: 'Austin News Headline Roman';
    src: url('../fonts/AustinNewsHeadline-Roman.eot');
    src: url('../fonts/AustinNewsHeadline-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AustinNewsHeadline-Roman.woff2') format('woff2'),
        url('../fonts/AustinNewsHeadline-Roman.woff') format('woff'),
        url('../fonts/AustinNewsHeadline-Roman.ttf') format('truetype'),
        url('../fonts/AustinNewsHeadline-Roman.svg#AustinNewsHeadline-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}