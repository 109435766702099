.bg-squares {
    background-image: /* tint image */
                        linear-gradient(to right, rgba(192, 192, 192, 0.75), rgba(192, 192, 192, 0.75)),
                        /* checkered effect */
                        linear-gradient(to right, black 50%, white 50%),
                        linear-gradient(to bottom, black 50%, white 50%);
    background-blend-mode: normal, difference, normal;
    background-size: 2em 2em;
}

.vabadus {
    font-family: 'Exo', sans-serif;
    font-weight: 700;
}
.swal-soundCite .swal2-content .swal2-html-container{
  min-height: 40vh;
}
.text-error{
  color: rgb(235, 48, 48);
}

#togglePassword {
  color: #8F8F8F;
  position: absolute;
  right: 20px;
  bottom: 8px;
  &:hover {
    color: #181C32;
    cursor: pointer;
  }
}

p[id^="rw-"] {
  position: relative;
  border: 2px dashed #007bff; /* Línea punteada azul */
  padding: 5px;
  margin: 10px 0;
  background-color: #e9f5ff; /* Fondo azul claro */
  border-radius: 5px;
  display: inline-block; /* Para que el texto se ajuste al contenido */
}

p[id^="rw-"]::before {
  content: attr(data-ancla);
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #007bff; /* Color del fondo de la etiqueta */
  color: #fff; /* Color del texto de la etiqueta */
  padding: 2px 2px;
  border-radius: 3px;
  font-size: 12px;
}

.sticky-column-ancla {
  position: sticky;
  top: 200px; /* Altura de la barra superior fija */
  align-self: flex-start;
  max-height: calc(100vh - 50px); /* Ajustar altura máxima */
  overflow-y: auto;
}

.rw-fixed-episode {
  display: block;
}

.rw-rotating-episode {
  display: none;
}
