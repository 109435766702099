@import url("https://fonts.googleapis.com/css2?family=Exo:wght@700&family=Poppins:wght@300;400;500;600;700&family=Playfair+Display:wght@400;700&family=Raleway&family=Pontano+Sans&display=swap");


@import "~@ckeditor/ckeditor5-editor-classic/theme/classiceditor.css";

@import "./../../node_modules/cropperjs/dist/cropper.css";

@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css";
@import "../metronic/plugins/flaticon/flaticon.css";
@import "../metronic/plugins/flaticon2/flaticon.css";
@import "../metronic/plugins/keenthemes-icons/font/ki.css";
@import "./login-4.css";
@import "./error-5.css";
@import "./error-6.css";
@import "./plugins.bundle.css";
@import "../metronic/sass/style.scss";

@import "./font_austin";

@import 
    "cms/base",
    "cms/button",
    "cms/card",
    "cms/ckeditor",
    "cms/dropdown",
    "cms/form",
    "cms/jwplayer",
    "cms/meta_preview",
    "cms/table",
    "cms/modal",
    "cms/utils",
    "cms/preview"
;
