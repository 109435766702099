.modal .modal-full {
    width: 95%;
    max-width: none;
    height: 90%;
    margin: 20px auto;
  
    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
  
    .modal-body {
        overflow-y: auto;
    }
}

.modal.modal-sticky.modal-sticky-bottom-right {
    overflow: inherit;
}