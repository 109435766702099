//
// Override global variables
//

// Theme colors
$primary:       									#000;
$primary-hover:    									#505050;
$primary-light:                                     #C0C0C0;
$primary-inverse:  									#FFFFFF;

// Border Radiues
$border-radius:            						    .85rem;
