.btn-sm {
    &.spinner.spinner-right.btn:not(.btn-block).spinner-sm {
        padding-right: 2.5rem;
    }
    
    &.spinner.spinner-sm:before {
        width: 0.80rem;
        height: 0.80rem;
        margin-top: -0.40rem;
    }
  
    &.spinner.spinner-white:before {
        border: 1px solid #ffffff;
        border-right: 1px solid transparent;
    }
}

.a-config-modal.btn.btn-hover-primary:focus:not(.btn-text), .a-config-modal.btn.btn-hover-primary.focus:not(.btn-text) {
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important;
    color: #000 !important;
}

.a-config-modal.btn.btn-hover-primary:focus:not(.btn-text) i, .a-config-modal.btn.btn-hover-primary.focus:not(.btn-text) i {
    color: #000 !important;
}