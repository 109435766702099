#hyperplayer {
  width: 100% !important;
  margin-bottom: 40px;
}

/* Set the idle overlay to transparent white */
.jwplayer.jw-state-idle .jw-controls {
  background: rgba(0, 0, 0, 0);
}

/* Hide titles on complete */
.jwplayer.jw-state-complete .jw-title .jw-title-primary,
.jwplayer.jw-state-complete .jw-title .jw-title-secondary {
  display: none;
}

/* Round, white play & restart buttons with black icons */
.jwplayer .jw-display-controls .jw-display-icon-container,
.jwplayer .jw-display-controls .jw-display-icon-container {
  background-color: black;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.jwplayer .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer .jw-display-controls .jw-display-icon-container .jw-icon {
  color: black;
  width: 60px;
  height: 60px;
  padding: 20px;
}

/* Change play button colors on idle state, handle hover transitions */
.jwplayer.jw-state-idle .jw-display-controls .jw-display-icon-container {
  background-color: #92DCE5;
  height: 80px;
  width: 80px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

.jwplayer.jw-state-idle .jw-display-controls .jw-display-icon-container .jw-icon {
  color: #000;
  height: 40px;
  width: 40px;
  padding: 20px;
}


/* Shrink play button on smaller breakpoints */
.jwplayer.jw-state-idle.jw-breakpoint-3 .jw-display-controls .jw-display-icon-container,
.jwplayer.jw-state-idle.jw-breakpoint-2 .jw-display-controls .jw-display-icon-container  {
  height: 60px;
  width: 60px;
}

.jwplayer.jw-state-idle.jw-breakpoint-1 .jw-display-controls .jw-display-icon-container,
.jwplayer.jw-state-idle.jw-breakpoint-0 .jw-display-controls .jw-display-icon-container {
  height: 40px;
  width: 40px;
}

.jwplayer.jw-state-idle.jw-breakpoint-3 .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer.jw-state-idle.jw-breakpoint-2 .jw-display-controls .jw-display-icon-container .jw-icon {
  height: 40px;
  width: 40px;
  padding: 10px;
}

.jwplayer.jw-state-idle.jw-breakpoint-1 .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer.jw-state-idle.jw-breakpoint-0 .jw-display-controls .jw-display-icon-container .jw-icon {
  height: 20px;
  width: 20px;
  padding: 10px;
}

.jwplayer.jw-state-idle .jw-display-controls .jw-display-icon-container:hover {
  background-color: #92DCE5;
}

.jwplayer.jw-state-idle .jw-display-controls .jw-display-icon-container .jw-icon:hover {
  color: #000;
}

.jwplayer.jw-state-paused .jw-controls {
  background-color: rgba(147, 221, 230, 0);
}


/* Customize font style and positioning for titles */
.jwplayer.jw-state-idle .jw-title {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Apply new styles to video title and description, handle transitions */
.jwplayer .jw-title-primary {
  font-size: 48px;
  padding: 30px;
  color: #1E1E1E;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  padding-bottom: 15px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

/* Apple new styles to video description */
.jwplayer .jw-title-secondary {
  font-size: 18px;
  max-width: 70%;
  font-weight: 500;
  opacity: 0;
  margin: 0 auto;
  color: #1E1E1E;
  text-align: center;
  font-family: 'Merriweather', serif;
  white-space: normal;
  line-height: 1.3;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
  -webkit-transition-delay: 0.08s;
  transition-delay: 0.08s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

/* Decrease font size on medium-small breakpoints */
.jwplayer.jw-breakpoint-3 .jw-title-primary,
.jwplayer.jw-breakpoint-2 .jw-title-primary,
.jwplayer.jw-breakpoint-1 .jw-title-primary,
.jwplayer.jw-breakpoint-0 .jw-title-primary {
  font-size: 22px;
}

.jwplayer.jw-breakpoint-3 .jw-title-secondary,
.jwplayer.jw-breakpoint-2 .jw-title-secondary {
  font-size: 14px;
}

/* Hide the description on smallest breakpoints */
.jwplayer.jw-breakpoint-1 .jw-title-secondary,
.jwplayer.jw-breakpoint-0 .jw-title-secondary {
  display: none;
}

/* Change colors and remove gradient shadow for control bar, change icon color on pause */
.jwplayer .jw-display {
  padding-bottom: 45px;
}

.jwplayer .jw-controlbar {
  background-color: rgba(0, 0, 0, 0);
}

.jwplayer .jw-controlbar .jw-button-container .jw-button-color {
  color: black;
}

.jwplayer.jw-state-paused .jw-controlbar .jw-button-container .jw-button-color {
  color: black;
}

.jwplayer .jw-controlbar .jw-button-container .jw-button-color:hover {
  opacity: 0.8;
}


/* Move tooltips closer to control bar with inline timeslider, change colors, round corners, remove shadow */
.jwplayer .jw-controlbar .jw-tooltip {
  bottom: 70%;
  box-shadow: none;
}

.jwplayer .jw-controlbar .jw-tooltip.jw-open {
  border-radius: 5px;
  color: #92DCE5;
}

.jwplayer .jw-controlbar .jw-tooltip .jw-text {
  background-color: #92DCE5;
  border-radius: 5px;
  border: 0;
}

.jwplayer .jw-controlbar .jw-text {
  color: black;
}

.jwplayer .jw-volume-tip,
.jwplayer .jw-time-tip {
  padding-bottom: 0;
}


/* Customize colors of volume control knob */
.jwplayer .jw-overlay .jw-slider-volume .jw-slider-container .jw-knob {
  background-color: black;
}


/* Customize colors of timeslider */
.jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-rail {
  background-color: rgba(0, 0, 0, 0.3);
}

.jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-buffer {
  background-color: rgba(0, 0, 0, 0.3);
}

.jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-progress {
  background-color: black;
}

.jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-knob {
  background-color: black;
}


/* Customize colors/opacity of the settings menu */
.jwplayer .jw-settings-menu {
  background-color: transparent;
}

.jwplayer .jw-settings-menu .jw-settings-topbar {
  background-color: #92DCE5;
}

.jwplayer .jw-settings-menu .jw-settings-topbar .jw-icon:hover {
  color: #1E1E1E;
}

.jwplayer .jw-settings-menu .jw-settings-submenu {
  background-color: rgba(0, 0, 0, .65);
}

.jwplayer .jw-settings-menu .jw-settings-content-item {
  color: #1E1E1E;
}

.jwplayer .jw-settings-menu .jw-settings-content-item:hover,
.jwplayer .jw-settings-menu .jw-settings-item-active {
  color: #92DCE5;
  font-weight: bold;
}

.jw-flag-audio-player {
  background-color: rgba(0, 0, 0, 0) !important;
}

.jw-wrapper {
  background-color: rgba(0, 0, 0, 0) !important;
}

.jw-controls-backdrop {
  background: none !important;
}

.jwplayer .jw-controlbar .jw-button-container .jw-icon-playback .jw-svg-icon {
  height: 50px;
  width: 50px;
}

.jw-progress {
  background-color: black !important;
}

.jw-logo-button {
  display: none !important;
}

#hyperplayer .jw-button-color.jw-toggle:not(.jw-icon-cast), #hyperplayer .jw-button-color:hover:not(.jw-icon-cast), #hyperplayer .jw-button-color:focus:not(.jw-icon-cast), #hyperplayer .jw-button-color.jw-toggle.jw-off:hover:not(.jw-icon-cast) {
  color: rgb(91, 198, 248) !important;
}

@media (max-width: 575px) {
  .jwplayer .jw-controlbar .jw-button-container .jw-button-color {
    color: #000 !important;
  }
  .jwplayer .jw-controlbar .jw-text {
    color: #000 !important;
  }
  .jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-buffer {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-rail {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-knob {
    background-color: #000 !important;
  }
  .jwplayer .jw-controlbar .jw-slider-time .jw-slider-container .jw-progress {
    background-color: #000 !important;
  }
  .jw-slider-time .jw-rail, .jw-horizontal-volume-container .jw-rail {
    background-color: rgba(0,0,0,0.2) !important;
  }
  .jw-slider-time .jw-buffer, .jw-horizontal-volume-container .jw-buffer {
    background-color: rgba(0,0,0,0.4) !important;
  }
  .jw-progress {
    background-color: #000 !important;
  }
  .jw-knob {
    background-color: #000 !important;
  }
  .jw-flag-audio-player.jw-breakpoint-1 .jw-horizontal-volume-container.jw-open~.jw-slider-time, .jw-flag-audio-player.jw-breakpoint-2 .jw-horizontal-volume-container.jw-open~.jw-slider-time {
    opacity: 1 !important;
  }
}