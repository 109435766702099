.card-seo-google {
    position: relative;
    display: block;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    margin: 0 -10px;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    font-family: Arial, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased
}

@media only screen and (min-width: 770px) {
    .card-seo-google {
        width: auto;
        box-shadow: none;
        margin: 0;
        padding: 0
    }
}

.card-seo-google__title {
    display: block;
    letter-spacing: normal;
    color: #1a0dab;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media only screen and (min-width: 770px) {
    .card-seo-google__title {
        font-size: 20px;
        line-height: 1.2
    }
}

.card-seo-google__title:hover {
    text-decoration: underline
}

.card-seo-google__url {
    display: block;
    height: 17px;
    white-space: nowrap;
    border-bottom: 1px solid #ddd;
    padding-bottom: 22px;
    margin-bottom: 6px
}

@media only screen and (min-width: 770px) {
    .card-seo-google__url {
        border: none;
        padding: 0;
        margin: 0
    }
}

.card-seo-google__url-title {
    position: relative;
    top: -2px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: normal;
    color: #000;
    margin: 3px 0 4px;
}

.card-seo-google__url-arrow {
    display: none;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 3px;
    border-width: 5px 4px 0 4px;
    border-style: solid;
    border-color: #000 transparent
}

@media only screen and (min-width: 770px) {
    .card-seo-google__url-arrow {
        display: inline-block
    }
}

.card-seo-google__description {
    color: #545454;
    font-size: 14px;
    line-height: 1.4;
    word-wrap: break-word
}

.card-seo-google__date {
    color: #808080
}

.card-seo-twitter {
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    border-radius: 14px;
    border-style: solid;
    border-color: #E1E8ED;
    border-width: 1px;
    color: #292F33;
    margin-left: 44px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out
}

.card-seo-twitter:before {
    content: '';
    position: absolute;
    top: 0;
    left: -48px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #EBF1FA
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter {
        width: 506px;
        border-radius: 0.42857em;
        margin-left: 0;
        overflow: hidden
    }
    .card-seo-twitter:before {
        display: none
    }
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter:hover {
        background-color: #F5F8FA;
        border-color: rgba(136, 153, 166, 0.5)
    }
}

.card-seo-twitter__image {
    height: 150px;
    background-size: cover;
    background-color: #E1E8ED;
    background-repeat: no-repeat;
    background-position: center;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    overflow: hidden
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter__image {
        width: 504px;
        height: 252px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: hidden
    }
}

.card-seo-twitter__text {
    padding: 9px
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter__text {
        padding: 0.75em 1em
    }
}

.card-seo-twitter__title {
    display: block;
    margin: 0 0 0.15em;
    font-size: 14px;
    line-height: 18.375px;
    letter-spacing: normal;
    overflow: hidden
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1em;
        font-weight: bold;
        line-height: 1.3em;
        max-height: 1.3em
    }
}

.card-seo-twitter__description {
    display: none;
    height: 2.6em;
    max-height: 2.6em;
    margin-top: 0.32333em;
    line-height: 1.3em;
    letter-spacing: normal;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter__description {
        display: block;
        display: -webkit-box
    }
}

.card-seo-twitter__link {
    display: block;
    color: #657786;
    text-transform: lowercase;
    line-height: 1.3125;
    letter-spacing: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media only screen and (min-width: 770px) {
    .card-seo-twitter__link {
        margin-top: 0.32333em;
        color: #8899A6
    }
}

.card-seo-linkedin {
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media only screen and (min-width: 770px) {
    .card-seo-linkedin {
        width: 552px
    }
}

.card-seo-linkedin__image {
    height: 288px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #dadde1
}

@media only screen and (min-width: 770px) {
    .card-seo-linkedin__image {
        width: 552px
    }
}

.card-seo-linkedin__text {
    padding: 8px 16px;
    color: #1d2129;
    border: 1px solid #e6e9ec;
    border-top: none;
    display: flex;
    flex-grow: 1
}

.card-seo-linkedin__title {
    display: block;
    direction: ltr;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.9);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-seo-linkedin__description {
    border-collapse: separate;
    color: #606770;
    direction: ltr;
    display: -webkit-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 18px;
    line-height: 20px;
    margin-top: 3px;
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-box-orient: vertical;
    -webkit-font-smoothing: antialiased;
    -webkit-line-clamp: 1
}

.card-seo-linkedin__link {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 16px
}
